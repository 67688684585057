import React from 'react';
import { Grid, Box, Typography, useMediaQuery, Button } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import DividerLine from 'components/DividerLine';
import DividerSmall from 'components/DividerSmall';
import { ContentSectionData, ContentSectionItem } from 'types/domain/content';
import parse from 'react-html-parser';
import clx from 'classnames';

type ColWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface TwoColumnSectionProps {
  content: ContentSectionData;
  showDivider?: boolean;
  alternateRows?: boolean;
  columnWidths?: ColWidth[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  column: {
    textAlign: 'center',
    padding: `${theme.spacing(6)}px ${theme.spacing(6)}px `,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnLeft: {
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px `,
    },
  },
  columnRight: {
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px `,
    },
  },
  columnContent: {
    textAlign: 'left',
  },
  columnParagraph: {
    textAlign: 'left',
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    position: 'relative',
    width: '100%',
    maxWidth: 400,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
      width: '70%',
    },
  },
  ctaButton: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 0,
    },
  },
}));

const TwoColumnSection: React.FC<TwoColumnSectionProps> = ({
  content,
  showDivider = true,
  alternateRows = true,
  columnWidths = [7, 5],
}: TwoColumnSectionProps): JSX.Element => {
  const styles = useStyles({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderMain = (item: ContentSectionItem, isLeftColumn: boolean): JSX.Element => {
    const colClass = clx(styles.column, {
      [styles.columnLeft]: isLeftColumn,
      [styles.columnRight]: !isLeftColumn,
    });

    return (
      <Grid item xs={12} md={columnWidths[0]} className={colClass}>
        <div className={styles.columnContent}>
          <Typography component="h3" variant="h3">
            {parse(item.title)}
          </Typography>
          {item.paragraphs?.map((para, index) => (
            <div className={styles.columnParagraph} key={`para_${index}`}>
              <Typography key={`para_${index}`} component="p" variant="body1">
                {parse(para)}
              </Typography>
            </div>
          ))}
          {item.ctas &&
            item.ctas?.map((cta, index) => {
              return (
                <Button
                  key={`cta_${index}`}
                  className={styles.ctaButton}
                  variant="contained"
                  color="primary"
                  href={cta.href}
                  target={cta.target || '_self'}
                >
                  {cta.label}
                </Button>
              );
            })}
        </div>
      </Grid>
    );
  };

  const renderSecondary = (
    item: ContentSectionItem,
    isLeftColumn: boolean
  ): JSX.Element => {
    const colClass = clx(styles.column, {
      [styles.columnLeft]: isLeftColumn,
      [styles.columnRight]: !isLeftColumn,
    });

    return (
      <Grid item xs={12} md={columnWidths[1]} className={colClass}>
        <div className={styles.imageContainer}>
          {item.image && <img src={item.image} className={styles.image} alt="alt text" />}
        </div>
      </Grid>
    );
  };

  return (
    <Box py={6}>
      <Grid container>
        {showDivider && (
          <Grid item xs={12}>
            <DividerLine />
          </Grid>
        )}
        {content.title && (
          <Grid item xs={12}>
            <Typography component="h2" variant="h2">
              {parse(content.title)}
            </Typography>
            {content.description && (
              <Typography variant="body1" component="p">
                {parse(content.description)}
              </Typography>
            )}
          </Grid>
        )}
        {content.items.map((item, index) => {
          const reversed = isMobile
            ? true
            : item.reversed || (alternateRows && index % 2 !== 0);
          return (
            <Grid container className={styles.row} key={`item_${index}`}>
              {reversed ? renderSecondary(item, true) : renderMain(item, true)}
              {reversed ? renderMain(item, false) : renderSecondary(item, false)}
              {index < content.items.length - 1 && (
                <Grid item xs={12}>
                  <DividerSmall />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TwoColumnSection;
