import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  root: {
    height: 3,
    maxWidth: 150,
    textAlign: 'center',
  },
  l1: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
  },
  l2: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
  },
  c: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
  r1: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
  },
  r2: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
  },
}));

const DividerSmall: React.FC = (): JSX.Element => {
  const styles = useStyles({});

  return (
    <Box className={styles.box}>
      <Grid container className={styles.root}>
        <Grid item xs={1} className={styles.l1} />
        <Grid item xs={3} className={styles.l2} />
        <Grid item xs={4} className={styles.c} />
        <Grid item xs={3} className={styles.r1} />
        <Grid item xs={1} className={styles.r2} />
      </Grid>
    </Box>
  );
};

export default DividerSmall;
