import React from 'react';
import PageContainer from 'containers/PageContainer';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import HeroSection from 'components/HeroSection';
import ContentSection from 'components/ContentSection';
import TwoColumn from 'components/TwoColumnSection';
import {
  heroContent,
  introContent,
  homeServicesContent,
  extensionsContent,
  clientsContent,
} from 'assets/content/home';
import './_includes';
// import bgImage from 'assets/images/promo-background.png';
import bgImage from 'assets/images/promo-background-darker.jpg';
import { useTheme } from '@material-ui/core/styles';
import HelmetHead from 'components/HelmetHead';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  backToTop: {
    textAlign: 'center',
  },
}));

const HomePage: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const styles = useStyles({});

  return (
    <PageContainer>
      <HelmetHead
        title="distriqt - Digital development and consulting"
        url="https://distriqt.com"
      />
      <FullWidth backgroundImage={bgImage as string} useClipPath>
        <Section id="home">
          <HeroSection content={heroContent} />
        </Section>
      </FullWidth>
      <Section id="intro">
        <ContentSection content={introContent} showDivider={false} noBottomPadding />
      </Section>
      <Section id="services">
        <TwoColumn content={homeServicesContent} showDivider={true} />
      </Section>
      <FullWidth backgroundColor={theme.palette.primary.light}>
        <Section id="extensions">
          <ContentSection content={extensionsContent} />
        </Section>
      </FullWidth>
      <Section id="clients">
        <ContentSection content={clientsContent} />
      </Section>
      <div className={styles.backToTop}>
        <Button variant="outlined" color="primary" onClick={() => window.scrollTo(0, 0)}>
          Back to top
        </Button>
      </div>
    </PageContainer>
  );
};

export default HomePage;
